import axios from "axios";

import { message } from "antd";
import { store } from "..";

const $host = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL,
    /*     headers: {
            "Pragma": "no-cache",
            "Expires": -1,
            "Cache-Control": "no-cache"
        } */
})

const $authHost = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL,
    /*     headers: {
            "Pragma": "no-cache",
            "Expires": -1,
            "Cache-Control": "no-cache"
        } */
})

const authInterceptor = config => {
    config.headers.authorization = `Bearer ${localStorage.getItem('token')}`
    return config
}

$authHost.interceptors.request.use(authInterceptor)

$authHost.interceptors.response.use((response) => {
    localStorage.setItem('server-version', response.headers['server-version']);
    localStorage.setItem('client-version', response.headers['client-version']);
    return response;

}, async (error) => {
    const originalRequest = error.config;
  //  console.log("ERROR REQ222! ", error)
    if (error?.response?.status === 401 && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/refresh`, { withCredentials: true })
            localStorage.setItem('token', response.data.accessToken);
            return $authHost.request(originalRequest);
        } catch (e) {
            console.log('НЕ АВТОРИЗОВАН, переадресация на логин')
            store.setUser({})
            store.setAuth(false)
        }
    }
    if (error?.code == "ERR_NETWORK") {
        message.error(error?.message + "\n\r Ошибка сети, проверьте качество соединения с сервером!");
        //alert(error.message + "\n\r Ошибка сети, проверьте качество соединения с сервером!")
        console.log("Network Error ", error.message, error.name)
    }
    if (error?.response?.status === 500) {
        message.error(error.response.data.message)
        console.log("ERROR 500! ", error.response.data.message)
    }
    if (error?.response?.status === 400) {
        message.error(error.response.data.message)
        console.log("ERROR 400! ", error.response.data.message)
    }
    if (error?.response?.status === 404) {
        message.error("Ошибка при связи с сервером! " + error?.response?.data?.message)
        console.log("ERROR 404! ", error?.response?.data?.message)
    }
})

export {
    $host,
    $authHost
}