import { Children, useContext, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from '../';
import { Row, Col } from 'react-bootstrap';
import { fetchScheduleGridItemsByTeacher } from '../http/adminApi';
import TeacherBar from '../components/teacher/TeacherBar';
import TeacherClasses, { journalLoader } from '../components/teacher/classes/TeacherClasses';
import TeacherStudents from '../components/teacher/classes/TeacherStudents';
import TeacherStudent from '../components/teacher/classes/TeacherStudent';
import TeacherSubBar from '../components/teacher/TeacherSubBar';
import { fetchTeacherClasses, fetchTeacherClassesSubjects, fetchTeacherSchedule, fetchTeacherSubjects } from '../http/teacherApi';
import TeacherShedule from '../components/teacher/TeacherSheduleNew';
import AttendanceEdit from '../components/teacher/attendance/AttendanceEdit';
import TeacherModulesTableNM from '../components/teacher/syllabus/TeacherModulesTableNM';
import ThemesTable from '../components/teacher/syllabus/ThemesTable';
import RoutesTable from '../components/teacher/syllabus/RoutesTable';
import DescriptionsTable from '../components/teacher/syllabus/DescriptionsTable';
import TeacherStudentsMass from '../components/teacher/classes/TeacherStudentsMass';
import TeacherSetScoreNL from '../components/teacher/classes/TeacherSetScoreNL';
import TeacherAttendanceStat, { loaderAttendanceStat } from '../components/teacher/statistic/TeacherAttendanceStat';
import EventsCalendar from '../components/utilites/EventsCalendar';
import MenuBar from '../components/MenuBar';
import { Navigate, Outlet } from 'react-router-dom';
import { loader } from '../components/Root';
import Module from '../components/teacher/syllabus/Module';
import Silabus, { silabusAction, silabusLoader } from '../components/teacher/syllabus/Silabus';
import { moduleListLoader } from '../components/teacher/syllabus/ModulesList';
import Modules, { EmptySelected, modulesAction, modulesLoader } from '../components/teacher/syllabus/Modules';
import ModuleCard, { createModuleCard, destroyModuleCard, loaderModuleCard } from '../components/ModuleCard';
import ThemeCard, { actionThemeCard, createThemeCard, destroyThemeCard, loaderThemeCard } from '../components/ThemeCard';
import RouteCard, { actionRouteCard, createRouteCard, destroyRouteCard, loaderRouteCard } from '../components/RouteCard';
import { actionDescriptionCardList, createDescriptionCardList, destroyDescriptionCardList, loaderDescriptionCardList } from '../components/DescriptionCardList';
import DescriptionCard, { actionDescriptionCard, createDescriptionCard, destroyDescriptionCard, loaderDescriptionCard } from '../components/DescriptionCard';
import RouteListCard, { actionRouteListCard, actionThemeCard2, createRouteListCard, destroyRouteListCard, destroyThemeCard2, loaderRouteListCard } from '../components/RouteListCard';
import Modules2, { createModuleCard2, createThemeCard2, modulesAction2, modulesLoader2 } from '../components/teacher/syllabus/Modules2';
import DescriptionCardList from '../components/DescriptionCardList';
import { SelectClassSubject, selectClassSubjectAction, selectClassSubjectLoader } from '../components/teacher/syllabus/SelectClassSubject';
import { action } from '../components/admin/parentmeeting/AdminParentVisit';

const sideMenu = [
  { id: 1, name: "Расписание", link: "shedule" },
  /*   {id: 2, name: "Модули(модал)"}, */
  { id: 6, name: "Модули", link: "modules" },
  { id: 61, name: "Модули 2", link: "modules2" },
  //            { id: 6, name: "Модули", link: "modules" },
  //{ id: 4, name: "Классы", link: "classes" },
  { id: 7, name: "Журнал", link: "journal" },
  { id: 5, name: "Посещаемость", link: "attendance" },
  { id: 8, name: "Статистика посещаемости" , link: "attendancestat" },
  { id: 9, name: "Календарь событий", link: "events" },
  /* { id: 10, name: "Звезды" }, */
  //{ id: 3, name: "Настройки" },
]

const Teacher = observer(() => {

  const { teacher } = useContext(Context)
  const { admin } = useContext(Context)

  useEffect(() => {
    //console.log("STUDENT",user.user.id);
    //getClassByStudent(user.id).then(data => setStudentData(data))
    // TODO добыть класс из свойств пользователя.
    //getClassByUserID(user.user.id).then(clas => fetchSchedule(clas).then(data => student.setStudentSchedule(data)))
    //console.log("class",clas);
    //fetchSchedule(clas).then(data => student.setStudentSchedule(data))
    fetchTeacherSubjects().then(data => teacher.setSubjects(data))
    fetchTeacherClasses().then(data => teacher.setClasses(data))
    fetchTeacherClassesSubjects().then(data => teacher.setClassesSubjects(data))
    fetchTeacherSchedule().then(data => teacher.setTeacherSchedule(data))
    //fetchScheduleGridItemsByTeacher().then(data => admin.setLessonsInterval(data))
  }, [])

  return (
    <>


      <Row className='mt-2'>
        <Col md={2}>
          {/* <TeacherBar /> */}
          <MenuBar menu={sideMenu} />
        </Col>
        <Col md={9}>
          {/* <TeacherSubBar /> */}
          <Outlet />
          {/* 
          {Boolean(!teacher.selectedSubMenu?.id & teacher.selectedSideMenu?.id == '1') && <TeacherShedule />}
          //{Boolean(!teacher.selectedSubMenu?.id & teacher.selectedSideMenu?.id == '2') && <TeacherModulesTable />}
          {Boolean(!teacher.selectedSubMenu?.id & teacher.selectedSideMenu?.id == '4') && <TeacherClasses nextID={41} />}
          {teacher.selectedSubMenu?.id == '41' && <TeacherStudents nextID={42} />}
          {teacher.selectedSubMenu?.id == '42' && <TeacherStudent />}
          {teacher.selectedSubMenu?.id == '43' && <TeacherSetScoreNL />}
          {Boolean(!teacher.selectedSubMenu?.id & teacher.selectedSideMenu?.id == '7') && <TeacherClasses nextID={71} />}
          {teacher.selectedSubMenu?.id == '71' && <TeacherStudentsMass />}
          {Boolean(!teacher.selectedSubMenu?.id & teacher.selectedSideMenu?.id == '5') && <AttendanceEdit />}
          {Boolean(!teacher.selectedSubMenu?.id & teacher.selectedSideMenu?.id == '6') && <TeacherModulesTableNM />}
          {teacher.selectedSubMenu?.id == '61' && <ThemesTable />}
          {teacher.selectedSubMenu?.id == '62' && <RoutesTable />}
          {teacher.selectedSubMenu?.id == '63' && <DescriptionsTable />}
          {Boolean(!teacher.selectedSubMenu?.id & teacher.selectedSideMenu?.id == '8') && <TeacherClasses nextID={81} />}
          {teacher.selectedSubMenu?.id == '81' && <TeacherAttendanceStat />}
          {Boolean(!teacher.selectedSubMenu?.id & teacher.selectedSideMenu?.id == '9') && <EventsCalendar />} */}
        </Col>
      </Row>
    </>
  );
});

export const teacherRouter = [
  { index: true, element: < Navigate to='shedule' /> },
  { path: 'shedule', element: <TeacherShedule /> },
  //{ path: 'classes', element: <TeacherClasses nextID={41} /> },
  /*   {
      path: 'modules/', element: <TeacherModulesTableNM />, loader: modulesLoader, children: [
        { path: 'module/:moduleID', element: <Module />, loader: modulesLoader },    
      ]
    }, */
  {
    path: 'modules/*', element: <Modules />, loader: modulesLoader, action: modulesAction, children: [
      {
        path: ':selectedClassSubject', element: <Outlet />, children: [
          /* { path: ':key', element: <>key</> }, */
          { path: 'new', element: <>new module</>, loader: createModuleCard },
          {
            path: ':moduleID', element: <ModuleCard />, loader: loaderModuleCard, children: [ // <ModuleCard />
              { path: 'destroy', element: <>destroy</>, action: destroyModuleCard },
              { path: 'new', element: <></>, loader: createThemeCard },
            ]
          },

          {
            path: ':moduleID/:themeID', element: <ThemeCard />, loader: loaderThemeCard, children: [
              { path: 'destroy', element: <>destroy</>, action: destroyThemeCard },
              { path: 'new', element: <></>, loader: createRouteCard },
            ]
          },
          {
            path: ':moduleID/:themeID/:routeID', element: <RouteCard />, loader: loaderRouteCard, children: [
              { path: 'destroy', element: <>destroy</>, action: destroyRouteCard },
              { path: 'new', element: <></>, loader: createDescriptionCard },
            ]
          },
          {
            path: ':moduleID/:themeID/:routeID/:descriptionID', element: <DescriptionCard />, loader: loaderDescriptionCard, children: [
              { path: 'destroy', element: <>destroy</>, action: destroyDescriptionCard }
            ]
          },

        ]
      },
    ]
  },
  { path: 'modules2', element: <SelectClassSubject />, loader: selectClassSubjectLoader, action: selectClassSubjectAction },
  {
    path: 'modules2/:selectedClassSubject', element: <Modules2 />, loader: modulesLoader2, action: modulesAction2, children: [
      { path: 'new', element: <>new module</>, loader: createModuleCard2, action: createModuleCard2 },
      {
        path: ':moduleID', element: <></>, children: [ // <ModuleCard />
          //{ path: 'destroy', element: <>destroy</> },
          { path: 'new', element: <></>, action: createThemeCard2 },
        ]
      },

      { path: ':moduleID/:themeID/new', element: <></>, action: createRouteListCard },
      {
        path: ':moduleID/:themeID', element: <RouteListCard />, loader: loaderRouteListCard, action: actionRouteListCard, children: [
          //{ path: 'destroy', element: <>destroy</>, action: destroyThemeCard2 },
          {
            path: ':routeID', element: <DescriptionCardList />, loader: loaderDescriptionCardList, action: actionDescriptionCardList, children: [ // <RouteCard />
              {
                path: ':descriptionID', element: <>desc</>, loader: loaderDescriptionCardList, action: actionDescriptionCardList, children: [
                  { path: 'destroy', element: <>destroy</>, action: destroyDescriptionCardList }
                ]
              },
              { path: 'destroy', element: <>destroy</>, action: destroyRouteListCard },
              { path: 'new', element: <></>, action: createDescriptionCardList },
            ]
          }, {
            //path: ':routeID/:descriptionID', element: <></>, action: actionDescriptionCardList, children: [
            //{ path: 'destroy', element: <>destroy</>, action: destroyDescriptionCard }
            //]
          },
        ]
      },


    ]
  },
  {
    path: 'silabus/*', element: <Silabus />, loader: silabusLoader, action: silabusAction, children: [
      {
        path: ':selectedClassSubject/modules/*', element: <Modules />, loader: modulesLoader, children: [
          { path: 'destroy', element: <></>, action: modulesAction },
          { path: ':moduleID', element: <></> },
          { path: 'new/:type/:params', element: <>Новый</> }
        ]
      },
      //{ path: 'module/:moduleID', element: <Module />, loader: modulesLoader }, // надо доделать на новый роутер
    ]
  },
  { path: 'journal/*', element: <TeacherClasses nextID={71} />, loader: journalLoader, },
  { path: 'attendance', element: <AttendanceEdit /> },
  {
    path: 'attendancestat', element: <SelectClassSubject />, loader: selectClassSubjectLoader, action: selectClassSubjectAction, children: [
    ]
  },
  { path: 'attendancestat/:selectedClassSubject', element: <TeacherAttendanceStat />, loader: loaderAttendanceStat},
  { path: 'events', element: <EventsCalendar /> },


];


export default Teacher;