import React, { useContext, useState, useEffect } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { observer } from "mobx-react-lite";
import { Context } from '../..';
import '../navbar.css'
import { Container, Form } from 'react-bootstrap';
import FilesUpload from '../utilites/FilesUpload';
import { getFileList } from '../../http/teacherApi';

const StudentsSelector = observer(() => {
  const { parent } = useContext(Context)

  const [students, setStudents] = useState([])

  useEffect(() => {
    setStudents(parent.students)
  }, [parent.students])


  return (
    <Container>
      {console.log("PPP", students, parent.students.length)}
      {Boolean(students.length > 1) &&
        <Form className="mx-2">
          <Form.Select className="bg-transparent" bsPrefix="form-select navselect" value={parent.studentData?.id} aria-label="Default select example">
            {students.map(student =>
              <option onClick={() => parent.setStudentData(student)} key={student.id} value={student.id}>{student.name}</option>
            )}
          </Form.Select>
        </Form>
      }
    </Container>
  );
});

export default StudentsSelector;