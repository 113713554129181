import { Form, Outlet, redirect, useLoaderData, useNavigate, useSubmit } from "react-router-dom";
import SchoolBook from "./SchoolBook";
import { createModule, createTheme, deleteModule, deleteTheme, fetchModules, fetchTeacherClassesSubjects, fetchTree, modyfyModuleO, modyfyRouteO, modyfyThemeO } from "../../../http/teacherApi";
import dayjs, { Dayjs } from 'dayjs'
import { Card, Col, DatePicker, GetProps, Row, Tree } from "antd";
import { Key, ReactNode, useEffect, useMemo, useState } from "react";
import { ModuleType } from "../../ModuleCard";

import { Typography } from 'antd';
import DirectoryTreeDnD from "./DirectoryTreeDnD";
import { Button } from "react-bootstrap";
const { Paragraph } = Typography;

export async function createModuleCard2({ params }: any) {
  const { selectedClassSubject } = params;
  const [classID, subjectID] = selectedClassSubject.split('|');
  const moduleID = await createModule(classID, subjectID);
  console.log("new moduleID:", moduleID);
  return redirect('../' + moduleID) //({ moduleID });
}

export async function createThemeCard2({ params }: any) {
  const { moduleID } = params;
  if (moduleID) {
    const ret = await createTheme(moduleID);
    console.log("new themeID:", ret);
    return redirect('../' + ret);
  }
  return (".");
}


export async function modulesAction2({ params, request }: any) {
  console.log("modulesAction2", params);
  
  const {moduleID} = params
  const formData = await request.formData();
  const { query } = Object.fromEntries(formData);
  if (query) {
    console.log("modules2Action", JSON.parse(query));
    const { id, name, type, index, key, workType, color, begin, end } = JSON.parse(query);
    switch (type) {
      case "module": {
        if (name === "") {
          deleteModule(id);
          return redirect(".");
          break;
        }
        modyfyModuleO({ moduleID: id, name, index }); break;
      }
      case "theme": {
        if (name === "") {
          deleteTheme(id);
          return redirect(`.`);
          break;
        }
        modyfyThemeO({ themeID: id, name, index, workType, begin, end }); break;
      }
      //case "route": modyfyRouteO({ routeID: id, name, index, color }); break;
    }
    return redirect(String(key).replace(/-/gi, '/'))
  }
  return redirect(".")
}

export async function modulesLoader2({ params }: any) {
  //console.log("modulesLoader2", params);

  const { selectedClassSubject, moduleID, themeID, routeID, descriptionID } = params;

  const raw_key: string[] = []
  raw_key.push(moduleID);
  if (themeID) raw_key.push(themeID);
  if (routeID) raw_key.push(routeID);
  if (descriptionID) raw_key.push(descriptionID);

  const key = raw_key.join('-');
  //console.log("modulesLoader", selectedClassSubject);

  if (selectedClassSubject) {
    const rawClassSubjects = await fetchTeacherClassesSubjects();
    const target = rawClassSubjects.find((item: any) => item.id === selectedClassSubject)

    const [classID, subjectID] = selectedClassSubject.split('|');
    const modules = await fetchModules(target.classID, target.subjectID);
    const tree = await fetchTree(target.classID, target.subjectID);
    //console.log("loader retrun", { classID, subjectID, modules, target, tree, key });

    return { classID, subjectID, modules, target, tree, key };
  }
  return {};
}


interface LoaderType {
  target: { subjectName: string, className: string, id: string };
  classID: number;
  subjectID: number;
  classesSubjects: [];
  modules: ModuleType[];
  tree: DataNode[];
  key: Key;
}

interface DataNode {
  title: string | ReactNode;
  key: string | number;
  isLeaf?: boolean;
  children?: DataNode[];
  selectable?: boolean;
}

type DirectoryTreeProps = GetProps<typeof Tree.DirectoryTree>;

/* const updateTreeData = (list: DataNode[], key: React.Key, children: DataNode[]): DataNode[] =>
  list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });
 */

/* export const EmptySelected = () => {
  return (
    <Card >
      Выберете модуль в меню слева
    </Card>
  )

} */

export const key2number = (key: React.Key) => {
  //console.log('key2number', key);

  var params: string | number | undefined | string[] = undefined;
  var _type = 'module'
  var selected: Key[] = []
  var expanded: Key[] = []

  if (typeof (key) === 'number') {
    return { _type, id: key, selected, expanded }
  }
  if (typeof (key) === 'string') {
    params = key.split('-');

    switch (params.length) {
      case 1: _type = 'module'; expanded = [`${params[0]}`]; break;
      case 2: _type = 'theme'; expanded = [`${params[0]}`, `${params[0]}-${params[1]}`]; break;
      case 3: _type = 'route'; expanded = [`${params[0]}`, `${params[0]}-${params[1]}`, `${params[0]}-${params[1]}`]; break;
      case 4: _type = 'description'; expanded = [`${params[0]}`, `${params[0]}-${params[1]}`, `${params[0]}-${params[1]}-${params[2]}`, `${params[0]}-${params[1]}-${params[2]}`]; break;
      default: console.log('default');
    }

    selected = [key];
    return { _type, id: params[params.length - 1], selected, expanded }
  }
  return {}
}


export default function Modules2() {
  //const ret = useLoaderData() as any;
  //console.log("ret", ret);

  const { classID, subjectID, modules, classesSubjects, target, tree, key } = useLoaderData() as LoaderType;
  const navigate = useNavigate();

  const { id, _type, selected, expanded } = key2number(key);
  //console.log("Modules2", key, selected, expanded);

  const [treeData, setTreeData] = useState<DataNode[]>([]/* useMemo(() => tree, [modules]) */);
  const [selectedKeys, setSelectedKeys] = useState<Key[] | undefined>([key]);
  const [expandedKeys, setExpandedKeys] = useState<Key[] | undefined>([]);
  const submit = useSubmit();


  const change = (props: any) => {
    const { _type, id } = key2number(props?.key)
    console.log("change", props, _type, id);

    let formData = new FormData();
    formData.append("query", JSON.stringify({ ...props, type: _type, id, key }));
    submit(formData, { method: "POST" });

  }

  const _add = (props: any) => {
    return (<Row gutter={[2, 2]}>
      <Col span={11}>
        <Paragraph
          editable={{
            tooltip: 'Редактировать',
            onChange: (name) => change({ key: props?.key, name }),
            triggerType: ['icon'/* , 'text' */],
          }}
        >
          {props?.title}
        </Paragraph>
      </Col>

      <Col span={13}>
        {props?.theme &&
          <>
            <DatePicker name="begin" defaultValue={dayjs(props?.begin).isValid() ? dayjs(props?.begin) : undefined} onChange={(value) => change({ key: props?.key, begin: dayjs(value).format() })} />
            <DatePicker name="end" defaultValue={dayjs(props?.end).isValid() ? dayjs(props?.end) : undefined} onChange={(value) => change({ key: props?.key, end: dayjs(value).format() })} />
          </>
        }
      </Col>
    </Row>
    )
  }

  const _new = (text: any, key: any = '') => {
    return (
      <Form method="post" action={key + "new"}>
        <Button size="sm" type="submit">{text}</Button>
      </Form>
    )
  }

  const data = useMemo(() => ({
    get tree() {
      var ret: DataNode[] = [];

      if (tree) {
        for (const module of tree) {
          var theme: DataNode[] = [];
          if (module.children) {
            for (const item of module.children) {
              delete item?.children

              theme.push({ ...item, title: _add({ ...item, theme: true }), })
            }
            theme.push({ title: _new('Добавить тему', module.key + "/"), key: module.key + '-new', isLeaf: true, selectable: false })
          }
          ret.push({
            ...module,
            title: _add(module),
            children: theme,
          })
        }
      }
      ret.push({ title: _new('Добавить модуль'), key: 'new', isLeaf: true, selectable: false })
      return ret;
    }

  }), [tree])

  useEffect(() => {
    //console.log('useeffect', selected, expanded);

    setTreeData(data.tree)
    setSelectedKeys(selected);
    setExpandedKeys(expanded);
  }, [data])


  const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {
    //    const { _type, id } = key2number(keys[0])
    //    console.log('Trigger Select', keys, info, _type);
    setSelectedKeys(keys);
    //    if (_type === 'module') return;
    navigate(`${String(keys[0]).replace(/-/gi, '/')}`);
  };

  return (<Card title={target?.subjectName + '  -  ' + target.className} >
    <Row gutter={[8, 8]}>
      <Col span={12}>
        <DirectoryTreeDnD
          key={key}
          selectedKeys={selectedKeys}
          expandedKeys={expandedKeys}
          treeData={treeData}
          onSelect={onSelect}
          onChange={change}
          showIcon={false}
          expandAction={false}
        />
      </Col>
      <Col span={12}>
        <Outlet />
      </Col>
      <Col span={10}>
        <SchoolBook classID={classID} subjectID={subjectID} />
      </Col>
    </Row>
  </Card>
  )
}

