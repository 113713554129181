import React from 'react';
import { observer } from "mobx-react-lite";
import { Card, Tabs } from 'antd';
import AdminRefSubjects from './AdminRefSubjectsN';
import AdminRefWorkTypes from './AdminRefWorkTypes';
import AdminRefColors from './AdminRefColors';
import AdminScheduleGridRef from '../schedules/AdminScheduleGridRef';
import AdminRefSubjectStar from './AdminRefSubjectStar';
import AdminRefSkillStarDetails from './AdminRefSkillStarDetails';
import AdminRefCalendarLocations from './AdminRefCalendarLocations';
import AdminRefCalendarEventTypes from './AdminRefCalendarEventTypes';
import AdminRefResponsible from './AdminRefResponsible';
import AdminRefTacts from './AdminRefTacts';

const AdminRefsTabs = observer(() => {

    /*     const [students, setStudents] = useState([])
        const [parents, setParents] = useState([])
        const [teachers, setTeachers] = useState([])
        const [tutors, setTutors] = useState([])
        const [admins, setAdmins] = useState([]) */

    /*     useEffect(() => {
            userList('student').then(data => setStudents(data))
            userList('parent').then(data => setParents(data))
            userList('teacher').then(data => setTeachers(data))
            userList('tutor').then(data => setTutors(data))
            userList('ADMIN').then(data => setAdmins(data))
        }, []) */

    const items = [
        {
            label: 'Предметы',
            children: <AdminRefSubjects />,
            key: 'AdminRef1',
        },
        {
            label: 'Типы контроля',
            children: <AdminRefWorkTypes />,
            key: 'AdminRef2',
        },
        {
            label: 'Цвета',
            children: <AdminRefColors />,
            key: 'AdminRef3',
        },
        {
            label: 'Сетки расписания',
            children: <AdminScheduleGridRef />,
            key: 'AdminRef4',
        },
        {
            label: 'Места',
            children: <div><AdminRefCalendarLocations /></div>,
            key: 'AdminRef6',
        },
        {
            label: 'Типы событий',
            children: <div><AdminRefCalendarEventTypes /></div>,
            key: 'AdminRef7',
        },
        {
            label: 'Ответственные',
            children: <div><AdminRefResponsible /></div>,
            key: 'AdminRef8',
        },
        {
            label: 'Субьектная звезда',
            children: <div><AdminRefSkillStarDetails /></div>,
            key: 'AdminRef5',
        },
        {
            label: 'Учебные такты',
            children: <div><AdminRefTacts /></div>,
            key: 'AdminRef9',
        },
        {
            label: 'Предметная звезда',
            children: <div><AdminRefSubjectStar /></div>,
            key: 'AdminRef10',
        },
    ];

    return (
        <Card>
            <Tabs
                type="card"
                items={items}
            />
        </Card>
    );
});

export default AdminRefsTabs;